*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.page{
    height: 100vh;
    width: 100%;
    display:flex;
    grid-template-columns: repeat(auto-fit, 20rem);
    justify-content: center;
}

section{
    padding-block: min(20vh,10rem)
}

.head{
    background-color: #0E226A;
}

.side{
    background-color: #0E226A;
}


.current{
    padding: 15px;
    text-align: center;
}

.edit{
    padding: 15px;
    text-align: center;
}

.view{
    padding: 15px;
    text-align: center;
}

.product{
    padding: 15px;
    text-align: center;
}

.row{
    margin-bottom: 10px;
}

.chart{
    padding: 15px;
    text-align: center;
}

.failed{
    padding: 15px;
    text-align: center
}

.current_view{
    padding: 15px;
    text-align: center;
    font-size: 24px;
}

.current_add{
    text-align: center;
    padding: 50px;
    font-size:24px;
}

.current_return{
    text-align: center;
    padding: 30px;
    font-size: 24px;
}

.edit_add{
    padding: 30px;
    text-align: center;
    font-size: 24px;
}

.edit_edit{
    text-align: center;
    padding: 30px;
    font-size: 24px;
}

.edit_return{
    text-align: center;
    padding: 30px;
    font-size: 24px;
}

.product_product{
    text-align:center;
    font-size: 25px;
    padding:19px
}

.product_formula{
    text-align: center;
    font-size: 26px;
    padding: 34px;
}

.product_formula_all{
    text-align: center;
    font-size: 25px;
    padding: 33px;
}

.product_formula_product{
    text-align: center;
    font-size: 25px;
    padding: 26px;
}

.product_return{
    text-align: center;
    font-size: 24px;
    padding: 30px;
}

.chart_viscosity{
    text-align: center;
    font-size: 30px;
    padding: 35px;
}

.chart_chart{
    text-align: center;
    font-size: 30px;
    padding: 56px;
}

.chart_print{
    text-align: center;
    font-size: 30px;
    padding: 13px;
}

.chart_query{
    text-align: center;
    font-size: 30px;
    padding: 33px;
}

.chart_submission{
    text-align: center;
    font-size: 24px;
    padding: 30px;
}

.chart_return{
    text-align: center;
    font-size: 24px;
    padding: 30px;
}

/* Responsive for Dashbord */

@media (min-width:1399px){
    .current{
        text-align: center;
        padding: 30px;
    }
    .edit{
        text-align: center;
        padding: 30px;
    }
    .view{
        text-align: center;
        padding: 30px;
    }
    .product{
        text-align: center;
        padding: 32px;
    }
    .chart{
        text-align: center;
        padding: 30px;
    }
    .failed{
        text-align: center;
        padding: 30px;
    }
}

@media (max-width:1260px){
    .edit,.current,.view,.product{
        text-align: center;
        padding: 14px;
        font-size: 24px;
    }
}

@media (max-width:1252px){
    .edit,.current,.view,.product{
        text-align: center;
        padding: 13px;
    }
}

@media (max-width:1244px){
    .edit,.current,.view,.product{
        text-align: center;
        padding: 12px;
    }
}

@media (max-width:1236px){
    .edit,.current,.view,.product{
        text-align: center;
        padding: 11px;
    }
}

@media (max-width:1228px){
    .edit,.current,.view,.product{
        text-align: center;
        padding: 10px;
    }
}

@media (max-width:1220px){
    .edit{
        text-align: center;
        padding: 9px;
    }
}

@media (max-width:1212px){
    .edit,.current,.view{
        text-align: center;
        padding: 8px;
    }
    .product{
        text-align: center;
        padding: 9px;
    }
}

@media (max-width:1204px){
    .edit,.current,.view{
        text-align: center;
        padding: 7px;
    }
}

@media (max-width:1201px){
    .current,.view,.product{
        text-align: center;
        padding: 15px;
    }
}

@media (max-width:1200px){
    .edit{
        padding: 13px;
        text-align: center;
    }
}

@media (max-width:992px){
   .current{
    margin-bottom: 15px;
   }
}

@media(max-width:576px){
    .current{
        text-align: center;
    }
}

@media (max-width: 745px){
    .failed{
        text-align: center;
        padding:14px
    }
}

@media (max-width: 741px){
    .failed{
        text-align: center;
        padding:13px
    }
}

@media (max-width: 737px){
    .failed{
        text-align: center;
        padding:12px
    }
}

@media (max-width: 733px){
    .chart{
        text-align: center;
        padding: 30px;
    }
}

@media (max-width:720px){
    .current{
        text-align: center;
        padding: 30px;
    }
    .edit{
        text-align: center;
        padding: 13px;
    }
    .chart{
        text-align:center;
        padding: 30px;
    }
}

@media (max-width:702px){
    .chart{
        text-align:center;
        padding:26px
    }
}

@media (max-width:697px){
    .current{
        text-align: center;
        padding: 25px;
    }
}

@media (max-width:686px){
    .chart{
        text-align: center;
        padding: 25px;
    }
}

@media (max-width:682px){
    .chart{
        text-align: center;
        padding: 24px;
    }
}

@media (max-width:678px){
    .failed{
        text-align: center;
        padding: 24px;
    }
}

@media (max-width:677px){
    .edit{
        text-align: center;
        padding: 25px;
    }
}

@media (max-width:664px){
    .product{
        text-align: center;
        padding: 25px;
    }
    .view{
        text-align: center;
        padding: 25px;
    }
}

@media (max-width:576px){
    .edit{
        text-align: center;
        margin-bottom: 15px;
    }
    .view{
        text-align: center;
        margin-bottom: 15px;
    }
}

/* Responsive for Current Batch Data */

@media (max-width:1385px){
    .current_view{
        text-align: center;
        padding: 40px;
    }
    .current_add{
        text-align: center;
        padding: 60px;
    }
    .current_return{
        text-align: center;
        padding:40px;
        padding-bottom: 80px;
    }
}

@media (max-width:1383px){
    .current_view{
        text-align: center;
        padding: 39px;
        padding-bottom: 50px;
    }
}

@media (max-width:1375px){
    .current_view{
        text-align: center;
        padding: 35px;
        padding-bottom: 50px;
    }
}

@media (max-width:1354px){
    .current_return{
        text-align: center;
        padding: 35px;
       padding-bottom: 80px;
    }
}

@media (max-width:1343px){
    .current_view{
        text-align: center;
        padding: 34px;
       padding-bottom: 50px;
    }
}

@media (max-width:1335px){
    .current_view{
        text-align: center;
        padding: 33px;
        padding-bottom: 50px;
       
    }
}

@media (max-width:1327px){
    .current_view{
        text-align: center;
        padding: 30px;
        padding-bottom: 55px;
    }
}

@media (max-width:1314px){
    .current_return{
        text-align: center;
        padding: 30px;
        padding-bottom: 90px;
    }
}

@media (max-width:1303px){
    .current_view{
        text-align: center;
        padding: 28px;
        padding-bottom: 50px;
        
    }
}

@media (max-width:1287px){
    .current_view{
        text-align: center;
        padding:25px;
        padding-bottom: 55px;
    }
}

@media (max-width:1274px){
    .current_return{
        text-align: center;
        padding: 25px;
        padding-bottom: 90px;
    }
}

@media (max-width:1263px){
    .current_view{
        text-align: center;
        padding: 20px;
        padding-bottom: 60px;
    }
}

@media (max-width:1234px){
    .current_return{
        text-align: center;
        padding: 20px;
        padding-bottom: 95px;
    }
}

@media (max-width:1223px){
    .current_view{
        text-align: center;
        padding: 15px;
        padding-bottom: 70px;
    }
}

@media (max-width:1201px){
    .current_view{
        text-align: center;
        padding: 20px;
    }
    .current_add{
        text-align:center;
        padding: 55px;
    }
    .current_return{
        text-align: center;
        padding: 40px;
    }
}

@media (max-width:1200px){
    .current_view{
        text-align:center;
        padding:15px;
        font-size: 27px;
    }
}

@media (max-width:1189px){
    .current_return{
        text-align: center;
        padding: 30px;
        padding-bottom: 50px;
    }
}

@media (max-width:992px){
    .current_view{
        text-align: center;
        margin-bottom: 15px;
    }
    .current_add{
        text-align: center;
        padding: 38px;
    }
}

@media (max-width:792px){
    .current_return{
        text-align: center;
        padding: 30px;
    }
}

@media (max-width:781px){
   .current_view{
    text-align: center;
    padding: 17px;
    font-size: 25px;
   }
   .current_return{
    text-align: center;
    padding: 30px;
   }
}

@media (max-width:752px){
    .current_return{
        text-align: center;
        padding:28px
    }
}

@media (max-width:744px){
    .current_return{
        text-align: center;
        padding: 25px;
    }
}

@media(max-width:732px){
    .current_return{
        text-align: center;
        padding: 25px;
    }
}
@media (max-width:746px){
    .current_view{
        text-align: center;
        padding: 20px;
        font-size: 23px;
       }
}

@media (max-width:716px){
    .current_view{
        text-align: center;
        padding: 23px;
        font-size: 22px;
       }
}

@media (max-width:706px){
    .current_view{
        text-align: center;
        padding: 25px;
        font-size: 20px;
       }
}

@media (max-width:672px){
    .current_view{
        text-align: center;
        padding: 20px;
        font-size: 20px;
    }
    .current_add{
        text-align: center;
        padding: 30px;
    }
}

@media (max-width:652px){
    .current_view{
        text-align: center;
        font-size: 20px;
        padding: 17px;
    }
}

@media (max-width:640px){
    .current_add{
        text-align: center;
        padding:50px;
        font-size: 30px;
    }
    .current_view{
        text-align: center;
        font-size: 25px;
    }
}

@media (max-width:639px){
    .current_add{
        text-align: center;
        padding: 54px;
        font-size: 26px;
    }
}

@media (max-width:617px){
    .current_add{
        text-align: center;
        padding:52px;
    }
}

@media (max-width:609px){
    .current_add{
        text-align: center;
        padding:50px;
    }
}

@media (max-width:601px){
    .current_view{
        text-align: center;
        padding: 30px;
    }
}

@media (max-width:576px){
    .current_add{
        text-align: center;
        padding: 20px;
        margin-bottom: 15px;
    }
}

/* Responsive for Edit Raw Material Data */

@media (max-width:1200px){
    .edit_add{
        text-align: center;
        padding:30px;
        font-size: 25px;
    }
}

@media (max-width:992px){
    .edit_add{
        text-align: center;
        margin-bottom: 10px;
    }
}

@media (max-width:771px){
    .edit_add{
        text-align: center;
        font-size: 28px;
        padding: 30px;
    }
    .edit_edit{
        text-align: center;
        padding: 35px;
    }
    .edit_return{
        text-align: center;
        padding: 35px;
    }
}

@media (max-width:581px){
    .edit_add{
        text-align: center;
        font-size: 23px;
        padding: 35px;
    }
    .edit_edit{
        text-align: center;
        padding:30px;
        font-size: 25px;
    }
}

@media (max-width:576px){
    .edit_edit{
        text-align: center;
        margin-bottom: 10px;
    }
}

/* Responsive for Product Spec Data */

@media (max-width:1200px){
    .product_formula_product{
        text-align: center;
        padding:5px;
    }
}

@media (max-width:992px){
    .product_product{
        text-align: center;
        padding: 20px;
        margin-bottom: 15px;
    }
    .product_formula{
        text-align: center;
        margin-bottom: 10px;
    }
    .product_formula_all{
        text-align: center;
        padding: 35px;
    }
    .product_formula_product{
        text-align: center;
        padding: 20px;
    }
}

@media (max-width:993px){
    .product_formula_all{
        text-align: center;
        padding: 23px;
    }
}

@media (max-width:992px){
    .product_formula_all{
        text-align: center;
        padding: 38px;
    }
}

@media (max-width:903px){
    .product_formula_all{
        text-align: center;
        padding: 35px;
    }
}

@media (max-width:891px){
    .product_formula_all{
        text-align: center;
        padding: 30px;
        font-size: 23px;
        padding-bottom: 50px;
    }
}

@media (max-width:879px){
    .product_formula_product{
        text-align: center;
        padding: 30px;
    }
    .product_formula_all{
        text-align: center;
        padding: 35px;
    }
}

@media (max-width:709px){
    .product_formula{
        text-align: center;
        padding: 40px;
        font-size: 25px;
    }
}

@media (max-width:683px){
    .product_formula_product{
        text-align: center;
        padding: 30px;
        font-size: 23px;
    }
}

@media (max-width:665px){
    .product_formula_all{
        text-align: center;
        padding: 40px;
        font-size: 23px;
    }
    .product_formula_product{
        text-align: center;
        padding: 40px;
    }
}

@media (max-width:649px){
    .product_formula_product{
        text-align: center;
        padding: 38px;
    }
}

@media (max-width:641px){
    .product_formula_product{
        text-align: center;
        padding: 35px;
    }
    .product_formula_all{
        text-align: center;
        padding: 35px;
    }
}

@media (max-width:629px) {
    .product_formula_all{
        text-align: center;
        padding: 30px;
    }
    .product_formula_product{
        text-align: center;
        padding: 30px;
    }
}

@media (max-width:609px){
    .product_formula_all{
        text-align: center;
        padding: 25px;
    }
    .product_formula_product{
        text-align: center;
        padding: 25px;
    }
}

@media (max-width:589px){
    .product_formula_all{
        text-align: center;
        padding: 20px;
    }
    .product_formula_product{
        text-align: center;
        padding: 20px;
    }
}

@media(max-width:576px){
    .product_formula{
        text-align: center;
        padding: 20px;
    }
    .product_formula_all{
        text-align: center;
        margin-bottom: 10px;
    }
}

/* Responsive for Charts and Reports */

@media (max-width:1441px){
    .chart_viscosity{
        text-align: center;
        padding: 50px;
    }
    .chart_print{
        text-align: center;
        padding: 30px;
    }
}

@media (max-width:1400px){
    .chart_viscosity{
        text-align: center;
        padding: 30px;
    }
    .chart_print{
        text-align: center;
        padding: 10px;
    }
}

@media (max-width:1200px){
    .chart_viscosity{
        text-align:center;
        padding: 47px;
    }
}

@media (max-width:992px){
    .chart_viscosity{
        text-align: center;
        padding: 35px;
        margin-bottom: 15px;
    }
    .chart_chart{
        text-align: center;
        padding: 35px;
    }
}

@media (max-width:815px){
    .chart_return{
        text-align: center;
        padding: 29px;
    }
}

@media (max-width:811px){
    .chart_return{
        text-align: center;
        padding: 28px;
    }
}

@media (max-width:807px){
    .chart_return{
        text-align: center;
        padding: 27px;
    }
}

@media (max-width:803px){
    .chart_return{
        text-align: center;
        padding: 26px;
    }
}

@media (max-width:799px){
    .chart_return{
        text-align: center;
        padding: 25px;
    }
}

@media (max-width:795px){
    .chart_submission{
        text-align: center;
        padding: 40px;
    }
}

@media (max-width:794px){
    .chart_print{
        text-align: center;
        padding: 30px;
    }
}

@media (max-width:784px){
    .chart_return{
        text-align: center;
        padding: 40px;
    }
}

@media (max-width:686px){
    .chart_query{
        text-align: center;
        padding: 50px;
    }
}

@media (max-width:654px){
    .chart_query{
        text-align: center;
        padding: 49px;
    }
}

@media (max-width:650px){
    .chart_query{
        text-align: center;
        padding: 47px;
    }
}

@media (max-width:642px){
    .chart_query{
        text-align: center;
        padding: 46px;
    }
}

@media (max-width:638px){
    .chart_query{
        text-align: center;
        padding: 45px;
    }
}

@media (max-width:623px){
    .chart_chart{
        text-align: center;
        padding: 55px;
    }
}

@media (max-width:606px){
    .chart_chart{
        text-align: center;
        padding: 54px;
    }
}

@media (max-width:602px){
    .chart_chart{
        text-align: center;
        padding: 53px;
    }
}

@media (max-width:598px){
    .chart_chart{
        text-align: center;
        padding: 52px;
    }
}

@media (max-width:594px){
    .chart_chart{
        text-align: center;
        padding: 50px;
    }
}

@media (max-width:586px){
    .chart_chart{
        text-align: center;
        padding: 49px;
    }
}

@media (max-width:582px){
    .chart_chart{
        text-align: center;
        padding: 48px;
    }
}

@media (max-width:578px){
    .chart_chart{
        text-align: center;
        padding: 47px;
    }
}

@media (max-width:577px){
    .chart_chart{
        text-align: center;
        font-size: 30px;
    }
}

@media (max-width:576px){
    .chart_chart{
        text-align: center;
        padding: 30px;
        margin-bottom: 15px;
    }
    .chart_print{
        text-align: center;
        margin-bottom: 15px;
    }
}

/* END */